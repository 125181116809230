import { isEmpty } from "lodash";
import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { QuestionType } from "../../../utils/constants";
import { compareAnswer } from "../../../utils/QuestionUtils";

export default function ListeningPartsResult({ result, onReturnBack }) {
  const [currentPassageIndex, setCurrentPassageIndex] = useState(0);

  const questionsRef = useRef([]);
  const scrollToQuestion = (passageIndex, questionNo) => {
    setCurrentPassageIndex(passageIndex);
    setTimeout(() => {
      questionsRef.current[questionNo - 1].scrollIntoView({
        behavior: "smooth",
      });
    }, 50);
  };

  const renderTrackTabList = (tracks) => {
    return (
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        {tracks?.map((_, index) => (
          <li className="nav-item" role="presentation" key={index}>
            <button
              className={`nav-link ${index === currentPassageIndex ? "active" : ""
                }`}
              type="button"
              role="tab"
              onClick={() => setCurrentPassageIndex(index)}
            >
              Track {index + 1}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const renderTrackQuestionNavigation = (track, trackIndex, result) => {
    return track?.questionGroups.map((section) => {
      return section.questions.map((question, index) => {
        const questionResult = result.examinationQuestionResults.find(
          (x) => x.questionId === question.id
        );
        let className = "list-item";
        if (questionResult?.answer) {
          if (questionResult.isRightAnswer) {
            className += " list-item-right-answer";
          } else {
            className += " list-item-wrong-answer";
          }
        }

        return (
          <span
            className={className}
            key={index}
            onClick={() => scrollToQuestion(trackIndex, question.questionNo)}
          >
            {question.questionNo}
          </span>
        );
      });
    });
  };

  const renderQuestionNavigationList = (tracks, result) => {
    return (
      <div className="test-question-list">
        {tracks?.map((item, index) => (
          <div className="test-question-list-part" key={index}>
            <h6 id={`part-${index + 1}`}>Track {index + 1}</h6>
            <div className="test-question-list-wrapper d-flex">
              {renderTrackQuestionNavigation(item, index, result)}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getClassNames = (result, question, currentAnswer) => {
    const questionResult = result.examinationQuestionResults.find(
      (x) => x.questionId === question.id
    );
    const isSelected =
      questionResult.answer.localeCompare(currentAnswer, undefined, {
        sensitivity: "accent",
      }) === 0;
    const isRightAnswer = question.answers.some((x) =>
      compareAnswer(currentAnswer, x)
    );

    if (isRightAnswer) {
      return "right-answer";
    } else if (isSelected && !questionResult.isRightAnswer) {
      return "wrong-answer";
    } else return "";
  };

  const renderTrackContent = (tracks, result) => {
    return (
      <div className="tab-content" id="pills-tabContent">
        {tracks?.map((track, index) => (
          <div
            key={index}
            className={`tab-pane fade ${index === currentPassageIndex ? "show active" : ""
              }`}
          >
            <div className="question-wrapper">
              {track.content && (
                <audio
                  className="listening-audio"
                  controls
                  playsinline
                  preload="metadata"
                  webkit-playsinline
                >
                  <source
                    src={track.content}
                    type="audio/mpeg"
                  />
                  <p>Your browser does not support audio element</p>
                </audio>
              )}
              <div
                className={`sections-wrapper border ${tracks.length > 1 && "sections-wrapper-multiple-section"
                  }`}
              >
                {track.questionGroups?.map((section, sectionIndex) => (
                  <div
                    className={
                      section.questionType === QuestionType.MutipleChoice.value
                        ? "section-multiple-wrapper"
                        : "section-wrapper border"
                    }
                    key={sectionIndex}
                  >
                    {section.questionType ===
                      QuestionType.MutipleChoice.value && (
                        <div className="listening-multiple-section">
                          <div className="section-header">
                            <h6>Section {sectionIndex + 1}</h6>
                          </div>
                          <div
                            className="unformat-bootstrap-table"
                            dangerouslySetInnerHTML={{
                              __html: section.description,
                            }}
                          />
                          <div className="section-content">
                            {section.questions?.map((question, questionIndex) => (
                              <div
                                className="question-container"
                                key={questionIndex}
                                id={question.questionNo}
                                ref={(el) =>
                                (questionsRef.current[question.questionNo - 1] =
                                  el)
                                }
                              >
                                <div className="question-content">
                                  <strong className="question-number">
                                    {question.questionNo}
                                  </strong>
                                  <div
                                    className="question"
                                    dangerouslySetInnerHTML={{
                                      __html: question.content,
                                    }}
                                  />
                                </div>
                                <div>
                                  <div className="option">
                                    {question.answerA && (
                                      <div
                                        className={`form-check ${getClassNames(
                                          result,
                                          question,
                                          "A"
                                        )}`}
                                      >
                                        A. {question.answerA}
                                      </div>
                                    )}
                                    {question.answerB && (
                                      <div
                                        className={`form-check ${getClassNames(
                                          result,
                                          question,
                                          "B"
                                        )}`}
                                      >
                                        B. {question.answerB}
                                      </div>
                                    )}
                                    {question.answerC && (
                                      <div
                                        className={`form-check ${getClassNames(
                                          result,
                                          question,
                                          "C"
                                        )}`}
                                      >
                                        C. {question.answerC}
                                      </div>
                                    )}
                                    {question.answerD && (
                                      <div
                                        className={`form-check ${getClassNames(
                                          result,
                                          question,
                                          "D"
                                        )}`}
                                      >
                                        D. {question.answerD}
                                      </div>
                                    )}
                                    {isEmpty(
                                      result.examinationQuestionResults.find(
                                        (x) => x.questionId === question.id
                                      )?.answer
                                    ) && (
                                        <div className="wrong-answer">
                                          Bạn chưa trả lời câu hỏi này
                                        </div>
                                      )}
                                  </div>
                                  {question.description && (
                                    <>
                                      <div className="description mt-2">
                                        Giải thích:
                                      </div>
                                      <div
                                        className="unformat-bootstrap-table"
                                        dangerouslySetInnerHTML={{
                                          __html: question.description,
                                        }}
                                      ></div>
                                    </>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                    {section.questionType === QuestionType.Text.value && (
                      <>
                        <div className="col-left">
                          <div className="section-header">
                            <h6>Section {sectionIndex + 1}</h6>
                          </div>
                          <div
                            className="unformat-bootstrap-table"
                            dangerouslySetInnerHTML={{
                              __html: section.description,
                            }}
                          ></div>
                        </div>
                        <div className="col-right d-flex row">
                          <div className="section-content">
                            {section.questions?.map(
                              (question, questionIndex) => (
                                <div
                                  className="question-container"
                                  key={questionIndex}
                                  id={question.questionNo}
                                  ref={(el) =>
                                  (questionsRef.current[
                                    question.questionNo - 1
                                  ] = el)
                                  }
                                >
                                  <div className="question-content">
                                    <strong className="question-number">
                                      {question.questionNo}
                                    </strong>
                                    <div
                                      className="question"
                                      dangerouslySetInnerHTML={{
                                        __html: question.content,
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <div className="mt-2">
                                      Câu trả lời của bạn:
                                    </div>
                                    <div
                                      className={
                                        result.examinationQuestionResults.find(
                                          (x) => x.questionId === question.id
                                        )?.isRightAnswer
                                          ? "right-answer"
                                          : "wrong-answer"
                                      }
                                    >
                                      {result.examinationQuestionResults.find(
                                        (x) => x.questionId === question.id
                                      ).answer ||
                                        "Bạn chưa trả lời câu hỏi này"}
                                    </div>
                                    <div className="mt-2">
                                      Câu trả lời đúng:
                                    </div>
                                    {question.answers?.map((answer, index) => (
                                      <>
                                        {index !== 0 && (
                                          <div className="mt-2">Hoặc:</div>
                                        )}
                                        <div>{answer}</div>
                                      </>
                                    ))}
                                    {question.description && (
                                      <div className="description">
                                        Giải thích: {question.description}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="listening-test-wrapper">
      <div className="col-lg-10">
        <div className="test-content" id="test-content">
          {/* PARTS NAVIGATION */}
          {renderTrackTabList(result.examination?.examinationCategories)}

          {/* MAIN CONTENT */}
          <div className="tab-content" id="pills-tabContent">
            {renderTrackContent(
              result.examination?.examinationCategories,
              result
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-2 border">
        {/* NUMBER NAVIGATION */}
        {renderQuestionNavigationList(
          result.examination?.examinationCategories,
          result
        )}
        {onReturnBack && (
          <Button className="w-100" onClick={onReturnBack}>
            Trở về
          </Button>
        )}
      </div>
    </div>
  );
}
