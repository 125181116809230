import { React } from "react";
import JuniorPartsResult from "../../../components/PraceticeTestParts/JuniorPartsResult";
import TestResultContainer from "../../../components/StudentTestParts/TestResultContainer";

export default function JuniorResultPage({ backUrl = "/junior" }) {
  return (
    <TestResultContainer
      backUrl={backUrl}
      renderResultDetail={(result, returnBack) => (
        <JuniorPartsResult result={result} onReturnBack={returnBack} />
      )}
    />
  );
}
