import { Tab, Tabs } from "react-bootstrap";
import ListeningParts from "../IeltsParts/ListeningParts";
import ListeningPartsResult from "../IeltsParts/ListeningPartsResult";
import { useGetExaminationByIdQuery } from "../../../apis/ExaminationApi";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import { useState } from "react";
import { useEffect } from "react";

export default function IeltsListeningLesson({
  lesson,
  resultExam,
  setResultExam,
}) {
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    setActiveTab(1);
  }, [lesson, setActiveTab]);

  const {
    isLoading: isGetLoading,
    isError: isGetError,
    isSuccess: isGetSuccess,
    data,
  } = useGetExaminationByIdQuery(lesson?.lessonExaminations[0]?.examinationId);

  return (
    <div>
      {lesson?.lessonAdditionalContents?.map(
        (additionContent) =>
          additionContent.name === "Audio" && (
            <div className="d-flex gap-2">
              <p>Track {additionContent.orderNo + 1}</p>
              <audio
                className="listening-audio"
                controls
                playsinline
                preload="metadata"
                webkit-playsinline
              >
                <source
                  src={additionContent.content}
                  type="audio/mpeg"
                />
                <p>Your browser does not support audio element</p>
              </audio>
            </div>
          )
      )}
      <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
        <Tab className="lesson-tab" eventKey="1" title="Script">
          <div
            className="unformat-bootstrap-table"
            dangerouslySetInnerHTML={{
              __html: lesson?.content,
            }}
          ></div>
        </Tab>
        <Tab className="lesson-tab" eventKey="2" title="Vocab">
          <div
            className="unformat-bootstrap-table"
            dangerouslySetInnerHTML={{
              __html: lesson?.lessonAdditionalContents?.find(
                (x) => x.name === "Vocabulary"
              )?.content,
            }}
          ></div>
        </Tab>
        <Tab className="lesson-tab" eventKey="3" title="Quiz">
          {isGetLoading && <LoadingIndicator />}
          {isGetError && <div>Có lỗi xảy ra</div>}
          {isGetSuccess && resultExam ? (
            <ListeningPartsResult
              result={resultExam}
              onReturnBack={() => setResultExam(undefined)}
            />
          ) : (
            data?.data && (
              <ListeningParts
                exam={data.data}
                submitResult={(result) =>
                  setResultExam({
                    ...result,
                    examination: data.data,
                  })
                }
              />
            )
          )}
        </Tab>
      </Tabs>
    </div>
  );
}
