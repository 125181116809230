import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { Fragment } from "react";
import Input from "../../Form/Input";
import { v4 } from "uuid";
import JuniorQuestionGroupEditForm from "./JuniorQuestionGroupEditForm";
import { useFileSource } from "../../../../hooks/FileHooks";
import Select from "../../Form/Select";
import { YesNoOptions } from "../../../../utils/constants";

export default function JuniorExaminationCategoryEditForm({
  handleChange,
  setFieldValue,
  prefix,
  examinationType,
  values,
  errors = {},
  touched = {},
}) {
  // Insert empty question group if not exist
  useEffect(() => {
    if (!values) {
      return;
    }

    // fix problem with orderNo
    for (let index = 1; index < values.questionGroups.length; index++) {
      if (
        values.questionGroups[index].orderNo !==
        values.questionGroups[index - 1].orderNo
      ) {
        continue;
      }

      for (
        let index2 = index;
        index2 < values.questionGroups.length;
        index2++
      ) {
        values.questionGroups[index2].orderNo++;
      }
    }

    const totalSections = values.numQuestionGroups;
    const currentSections = values.questionGroups.length;
    if (totalSections > currentSections) {
      for (let index = 0; index < totalSections; index++) {
        if (values.questionGroups.findIndex((x) => x.orderNo === index) > -1) {
          continue;
        }

        const insertIndex = values.questionGroups.findIndex(
          (x) => x.orderNo > index
        );
        const defaultQuestionGroup = createInitQuestionGroup(index);
        if (insertIndex === -1) {
          values.questionGroups.push(defaultQuestionGroup);
        } else {
          values.questionGroups.splice(insertIndex, 0, defaultQuestionGroup);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.id]);

  const createInitQuestionGroup = (orderNo) => {
    return {
      id: v4(),
      description: "",
      questionType: 0,
      numQuestions: 0,
      orderNo,
      questions: [],
    };
  };

  const handleChangeNumQuestionGroups = (event) => {
    const newValue = event.currentTarget.value;
    const questionGroups = values.questionGroups;
    const diff = newValue - values.numQuestionGroups;

    if (newValue > 10 || newValue < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số section có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    if (diff > 0) {
      const newItems = Array.from({ length: diff }, (_, index) => {
        return createInitQuestionGroup(values.numQuestionGroups + index);
      });
      questionGroups.push(...newItems);
    } else {
      const deleteCount = -diff;
      questionGroups.splice(questionGroups.length - deleteCount, deleteCount);
    }
    setFieldValue(`${prefix}.questionGroups`, questionGroups);
    handleChange(event);
  };

  const [hasListening, setHasListening] = React.useState(false);
  useEffect(() => {
    setHasListening(!isEmpty(values.content));
  }, [values.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const audioCallback = (result) => setFieldValue(`${prefix}.content`, result);
  const { fileSource, addFile } = useFileSource(values?.content, audioCallback);

  return (
    <div className="exam-container">
      <div className="form-group row">
        <div className="col-lg-4">
          <Input
            label="Tên part"
            name={`${prefix}.name`}
            placeholder="Tên part"
            required
            error={errors.name}
            touched={touched.name}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-4">
          <Input
            label="Điểm"
            name={`${prefix}.mark`}
            placeholder="Điểm"
            type="number"
            required
            error={errors.mark}
            touched={touched.mark}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-4">
          <Input
            label="Số section (1 - 10)"
            name={`${prefix}.numQuestionGroups`}
            placeholder="Số section"
            type="number"
            required
            error={errors.numQuestionGroups}
            touched={touched.numQuestionGroups}
            onChange={handleChangeNumQuestionGroups}
          />
        </div>
      </div>
      <div className="form-group row">
        <div className="col-lg-4">
          <Select
            name="hasListening"
            label="Thêm file nghe"
            onChange={(e) => setHasListening(e.currentTarget.value === "1")}
            value={hasListening ? "1" : "0"}
          >
            {Object.values(YesNoOptions).map((item) => (
              <option key={item.value} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
      </div>
      {hasListening && (
        <>
          <div className="form-group">
            <Input
              label="File nghe"
              name={`${prefix}.listeningFile`}
              placeholder="File nghe"
              type="file"
              accept="audio/*"
              onChange={addFile}
              error={errors.content}
              touched={touched.listeningFile}
            />
          </div>
          {fileSource && (
            <div className="form-group">
              <audio
                className="listening-audio"
                controls
                playsinline
                preload="metadata"
                webkit-playsinline
              >
                <source
                  src={fileSource}
                  type="audio/mpeg"
                />
                <p>Your browser does not support audio element</p>
              </audio>
            </div>
          )}
        </>
      )}
      {!isEmpty(values.questionGroups) &&
        values.questionGroups.map((item, index) => (
          <Fragment key={index}>
            <div className="separator"></div>
            <JuniorQuestionGroupEditForm
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              examinationType={examinationType}
              prefix={`${prefix}.questionGroups[${index}]`}
              sectionIndex={index}
              values={item}
              errors={errors.questionGroups && errors.questionGroups[index]}
              touched={touched.questionGroups && touched.questionGroups[index]}
            />
          </Fragment>
        ))}
    </div>
  );
}
