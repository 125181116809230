import { ExaminationType, UserRoles } from "./constants";

export const RoutingPath = {
  Admin: {
    User: "users",
    UserNew: "users/new",
    UserDetail: "users/edit/:id",
    JuniorTest: "tests/junior",
    JuniorTestNew: "tests/junior/new",
    JuniorTestDetail: "tests/junior/edit/:id",
    HighSchoolTest: "tests/highschool",
    HighSchoolTestNew: "tests/highschool/new",
    HighSchoolTestDetail: "tests/highschool/edit/:id",
    ReadingTest: "tests/reading",
    ReadingTestNew: "tests/reading/new",
    ReadingTestDetail: "tests/reading/edit/:id",
    WritingTest: "tests/writing",
    WritingTestNew: "tests/writing/new",
    WritingTestDetail: "tests/writing/edit/:id",
    ListeningTest: "tests/listening",
    ListeningTestNew: "tests/listening/new",
    ListeningTestDetail: "tests/listening/edit/:id",
    SpeakingTest: "tests/speaking",
    SpeakingTestNew: "tests/speaking/new",
    SpeakingTestDetail: "tests/speaking/edit/:id",
    IeltsCourse: "courses/ielts",
    IeltsCourseNew: "courses/ielts/new",
    IeltsCourseDetail: "courses/ielts/edit/:id",
    JuniorCourse: "courses/junior",
    JuniorCourseNew: "courses/junior/new",
    JuniorCourseDetail: "courses/junior/edit/:id",
    Lesson: "courses/:courseId/lessons",
    LessonNew: "courses/:courseId/lessons/new",
    LessonDetail: "courses/:courseId/lessons/edit/:id",
    LessonReadingTestNew:
      "courses/:courseId/lessons/edit/:lessonId/reading/new",
    LessonReadingTestDetail:
      "courses/:courseId/lessons/edit/:lessonId/reading/edit/:id",
  },
};

export const isIeltsCourse = (path) => path.includes("/courses/ielts");

export const getTestUrl = (test) => {
  switch (test.examinationType) {
    case ExaminationType.Reading:
      return `/ielts/reading/${test.id}`;
    case ExaminationType.Writing:
      return `/ielts/writing/${test.id}`;
    case ExaminationType.Listening:
      return `/ielts/listening/${test.id}`;
    case ExaminationType.Speaking:
      return `/ielts/speaking/${test.id}`;
    case ExaminationType.HighSchool:
      return `/highschool/${test.id}`;
    case ExaminationType.Junior:
      if (test.roles === UserRoles.Junior6) return `/junior6/${test.id}`;
      else if (test.roles === UserRoles.Junior7) return `/junior7/${test.id}`;
      else if (test.roles === UserRoles.Junior8) return `/junior8/${test.id}`;
      else return `/junior/${test.id}`;
    default:
      return "";
  }
};

export const getResultUrl = (examination, id) => {
  switch (examination.examinationType) {
    case ExaminationType.Reading:
      return `/ielts/reading/result/${id}`;
    case ExaminationType.Writing:
      return `/ielts/writing/result/${id}`;
    case ExaminationType.Listening:
      return `/ielts/listening/result/${id}`;
    case ExaminationType.Speaking:
      return `/ielts/speaking/${id}`;
    case ExaminationType.HighSchool:
      return `/highschool/result/${id}`;
    case ExaminationType.Junior:
      if (examination.roles === UserRoles.Junior6) return `/junior6/result/${id}`;
      else if (examination.roles === UserRoles.Junior7) return `/junior7/result/${id}`;
      else if (examination.roles === UserRoles.Junior8) return `/junior8/result/${id}`;
      else return `/junior/result/${id}`;
    default:
      return "";
  }
};
