import { React } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function TestResultSummary({ exam, result, testUrl }) {
  const navigate = useNavigate();
  return (
    <div className="py-8">
      <div className="mx-[300px] mt-8">
        <div className="d-flex items-center mb-4">
          <h4 className="text-3xl font-bold ">Kết quả bài test</h4>
          <i className="p-2 ml-2 font-bold text-white bg-blue-500 rounded-full fa fa-check"></i>
        </div>
        <div>
          <div className="d-flex justify-content-between mb-4">
            <p>Số câu trả lời đúng:</p>
            <p className="font-bold">
              <span className="text-blue-500">
                {result.examinationQuestionResults?.filter(
                  (x) => x.isRightAnswer
                )?.length ?? 0}
              </span>
              <span>/</span>
              {exam.numQuestions}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center gap-2">
        <Button variant="light" onClick={() => navigate(testUrl)}>
          Quay lại
        </Button>
        <Button onClick={() => navigate(`${testUrl}/result/${result.id}`)}>
          Review
        </Button>
      </div>
    </div>
  );
}
