import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import AdminLayout from "./app/components/Layouts/AdminLayout";
import MainLayout from "./app/components/Layouts/MainLayout";
import MasterContainer from "./app/components/Layouts/MasterContainer";
import PrivateRoute from "./app/components/PrivateRoute/PrivateRoute";
import CourseEditPage from "./app/pages/AdminCourse/AdminCourseEditPage";
import AdminIeltsCoursesPage from "./app/pages/AdminCourse/AdminIeltsCoursesPage";
import AdminJuniorCoursesPage from "./app/pages/AdminCourse/AdminJuniorCoursesPage";
import LessonEditPage from "./app/pages/AdminCourse/Lesson/LessonEditPage";
import LessonsPage from "./app/pages/AdminCourse/Lesson/LessonsPage";
import AdminResultsPage from "./app/pages/AdminResult/AdminResultsPage";
import AdminUserEditPage from "./app/pages/AdminUser/AdminUserEditPage";
import AdminUsersPage from "./app/pages/AdminUser/AdminUsersPage";
import Login from "./app/pages/Login/Login";
import NotFound from "./app/pages/NotFound/NotFound";
import ProfilePage from "./app/pages/Profile/ProfilePage";
import Register from "./app/pages/Register/Register";
import StudentCoursePage from "./app/pages/StudentCourse/StudentCoursePage";
import StudentIeltsCoursesPage from "./app/pages/StudentCourse/StudentIeltsCoursesPage";
import StudentJuniorCoursesPage from "./app/pages/StudentCourse/StudentJuniorCoursesPage";
import StudentDashboard from "./app/pages/StudentDashboard/StudentDashboard";
import ListeningInfoPage from "./app/pages/StudentPracticeTest/IeltsListening/ListeningInfoPage";
import ListeningPage from "./app/pages/StudentPracticeTest/IeltsListening/ListeningPage";
import ListeningResultPage from "./app/pages/StudentPracticeTest/IeltsListening/ListeningResultPage";
import ReadingInfoPage from "./app/pages/StudentPracticeTest/IeltsReading/ReadingInfoPage";
import ReadingPage from "./app/pages/StudentPracticeTest/IeltsReading/ReadingPage";
import ReadingResultPage from "./app/pages/StudentPracticeTest/IeltsReading/ReadingResultPage";
import SpeakingInfoPage from "./app/pages/StudentPracticeTest/IeltsSpeaking/SpeakingInfoPage";
import SpeakingPage from "./app/pages/StudentPracticeTest/IeltsSpeaking/SpeakingPage";
import SpeakingTestPage from "./app/pages/StudentPracticeTest/IeltsSpeaking/SpeakingTestPage";
import Junior6InfoPage from "./app/pages/StudentPracticeTest/Junior6Test/Junior6InfoPage";
import Junior6Page from "./app/pages/StudentPracticeTest/Junior6Test/Junior6Page";
import Junior6ResultPage from "./app/pages/StudentPracticeTest/Junior6Test/Junior6ResultPage";
import Junior7InfoPage from "./app/pages/StudentPracticeTest/Junior7Test/Junior7InfoPage";
import Junior7Page from "./app/pages/StudentPracticeTest/Junior7Test/Junior7Page";
import Junior7ResultPage from "./app/pages/StudentPracticeTest/Junior7Test/Junior7ResultPage";
import Junior8InfoPage from "./app/pages/StudentPracticeTest/Junior8Test/Junior8InfoPage";
import Junior8Page from "./app/pages/StudentPracticeTest/Junior8Test/Junior8Page";
import Junior8ResultPage from "./app/pages/StudentPracticeTest/Junior8Test/Junior8ResultPage";
import JuniorInfoPage from "./app/pages/StudentPracticeTest/JuniorTest/JuniorInfoPage";
import JuniorPage from "./app/pages/StudentPracticeTest/JuniorTest/JuniorPage";
import JuniorResultPage from "./app/pages/StudentPracticeTest/JuniorTest/JuniorResultPage";
import StudentResultPage from "./app/pages/StudentResult/StudentResultPage";
import StudentResultsPage from "./app/pages/StudentResult/StudentResultsPage";
import HighSchoolTestEditPage from "./app/pages/Test/HighSchoolTest/HighSchoolTestEditPage";
import HighSchoolTestsPage from "./app/pages/Test/HighSchoolTest/HighSchoolTestsPage";
import Junior6TestEditPage from "./app/pages/Test/JuniorTest/Junior6TestEditPage";
import Junior6TestsPage from "./app/pages/Test/JuniorTest/Junior6TestsPage";
import Junior7TestEditPage from "./app/pages/Test/JuniorTest/Junior7TestEditPage";
import Junior7TestsPage from "./app/pages/Test/JuniorTest/Junior7TestsPage";
import Junior8TestEditPage from "./app/pages/Test/Junior8Test/Junior8TestEditPage";
import Junior8TestsPage from "./app/pages/Test/Junior8Test/Junior8TestsPage";
import JuniorTestEditPage from "./app/pages/Test/JuniorTest/JuniorTestEditPage";
import JuniorTestsPage from "./app/pages/Test/JuniorTest/JuniorTestsPage";
import ListeningTestEditPage from "./app/pages/Test/ListeningTest/ListeningTestEditPage";
import ListeningTestsPage from "./app/pages/Test/ListeningTest/ListeningTestsPage";
import ReadingTestEditPage from "./app/pages/Test/ReadingTest/ReadingTestEditPage";
import ReadingTestsPage from "./app/pages/Test/ReadingTest/ReadingTestsPage";
import SpeakingTestEditPage from "./app/pages/Test/SpeakingTest/SpeakingTestEditPage";
import SpeakingTestsPage from "./app/pages/Test/SpeakingTest/SpeakingTestsPage";
import WritingTestEditPage from "./app/pages/Test/WritingTest/WritingTestEditPage";
import WritingTestsPage from "./app/pages/Test/WritingTest/WritingTestsPage";
import { UserRoles } from "./utils/constants";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route
          path=""
          element={
            <PrivateRoute
              roles={[
                UserRoles.Ielts,
                UserRoles.Junior,
                UserRoles.Junior6,
                UserRoles.Junior7,
                UserRoles.Junior8,
              ]}
            >
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<MasterContainer />}>
            <Route index element={<Navigate to="home" replace />} />
            <Route path="home" element={<StudentDashboard />} />
            <Route path="my-profile" element={<ProfilePage />} />
            <Route path="results" element={<StudentResultsPage />} />
            <Route path="results/:id" element={<StudentResultPage />} />
          </Route>
        </Route>

        <Route
          path=""
          element={
            <PrivateRoute roles={[UserRoles.Ielts]}>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<MasterContainer />}>
            <Route path="ielts/listening" element={<ListeningPage />} />
            <Route path="ielts/reading" element={<ReadingPage />} />
            <Route path="ielts/speaking" element={<SpeakingPage />} />
            <Route path="courses-ielts" element={<StudentIeltsCoursesPage />} />
          </Route>

          {/* Test Listening */}
          <Route path="ielts/listening/:id" element={<ListeningInfoPage />} />
          <Route
            path="ielts/listening/result/:id"
            element={<ListeningResultPage />}
          />

          {/* IELTS Reading */}
          <Route path="ielts/reading/:id" element={<ReadingInfoPage />} />
          <Route
            path="ielts/reading/result/:id"
            element={<ReadingResultPage />}
          />

          {/* IELTS Speaking */}
          <Route path="ielts/speaking/:id" element={<SpeakingInfoPage />} />
          <Route
            path="ielts/speaking/:id/start"
            element={<SpeakingTestPage />}
          />

          {/* Khóa học */}
          <Route path="courses-ielts/:id" element={<StudentCoursePage />} />
        </Route>

        <Route
          path=""
          element={
            <PrivateRoute roles={[UserRoles.Junior]}>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<MasterContainer />}>
            <Route path="junior" element={<JuniorPage />} />
            <Route
              path="courses-junior"
              element={<StudentJuniorCoursesPage />}
            />
          </Route>
          <Route path="junior/:id" element={<JuniorInfoPage />} />
          <Route path="junior/result/:id" element={<JuniorResultPage />} />
          <Route path="courses-junior/:id" element={<StudentCoursePage />} />
        </Route>

        <Route
          path=""
          element={
            <PrivateRoute roles={[UserRoles.Junior6]}>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<MasterContainer />}>
            <Route path="junior6" element={<Junior6Page />} />
          </Route>
          <Route path="junior6/:id" element={<Junior6InfoPage />} />
          <Route path="junior6/result/:id" element={<Junior6ResultPage />} />
        </Route>

        <Route
          path=""
          element={
            <PrivateRoute roles={[UserRoles.Junior7]}>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<MasterContainer />}>
            <Route path="junior7" element={<Junior7Page />} />
          </Route>
          <Route path="junior7/:id" element={<Junior7InfoPage />} />
          <Route path="junior7/result/:id" element={<Junior7ResultPage />} />
        </Route>

        <Route
          path=""
          element={
            <PrivateRoute roles={[UserRoles.Junior8]}>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="" element={<MasterContainer />}>
            <Route path="junior8" element={<Junior8Page />} />
          </Route>
          <Route path="junior8/:id" element={<Junior8InfoPage />} />
          <Route path="junior8/result/:id" element={<Junior8ResultPage />} />
        </Route>

        <Route
          path="admin"
          element={
            <PrivateRoute roles={[UserRoles.Admin]}>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          {/* User */}
          <Route index element={<Navigate to="users" replace />} />
          <Route path="users" element={<AdminUsersPage />} />
          <Route path="users/edit/:id" element={<AdminUserEditPage />} />
          <Route path="users/new" element={<AdminUserEditPage />} />

          {/* Test */}
          <Route path="tests/junior" element={<JuniorTestsPage />} />
          <Route
            path="tests/junior/edit/:id"
            element={<JuniorTestEditPage />}
          />
          <Route path="tests/junior/new" element={<JuniorTestEditPage />} />

          <Route path="tests/junior6" element={<Junior6TestsPage />} />
          <Route
            path="tests/junior6/edit/:id"
            element={<Junior6TestEditPage />}
          />
          <Route path="tests/junior6/new" element={<Junior6TestEditPage />} />

          <Route path="tests/junior7" element={<Junior7TestsPage />} />
          <Route
            path="tests/junior7/edit/:id"
            element={<Junior7TestEditPage />}
          />
          <Route path="tests/junior7/new" element={<Junior7TestEditPage />} />

          <Route path="tests/junior8" element={<Junior8TestsPage />} />
          <Route
            path="tests/junior8/edit/:id"
            element={<Junior8TestEditPage />}
          />
          <Route path="tests/junior8/new" element={<Junior8TestEditPage />} />

          <Route path="tests/highschool" element={<HighSchoolTestsPage />} />
          <Route
            path="tests/highschool/edit/:id"
            element={<HighSchoolTestEditPage />}
          />
          <Route
            path="tests/highschool/new"
            element={<HighSchoolTestEditPage />}
          />

          <Route path="tests/reading" element={<ReadingTestsPage />} />
          <Route
            path="tests/reading/edit/:id"
            element={<ReadingTestEditPage />}
          />
          <Route path="tests/reading/new" element={<ReadingTestEditPage />} />

          <Route path="tests/writing" element={<WritingTestsPage />} />
          <Route
            path="tests/writing/edit/:id"
            element={<WritingTestEditPage />}
          />
          <Route path="tests/writing/new" element={<WritingTestEditPage />} />
          <Route path="tests/speaking" element={<SpeakingTestsPage />} />
          <Route
            path="tests/speaking/edit/:id"
            element={<SpeakingTestEditPage />}
          />
          <Route path="tests/speaking/new" element={<SpeakingTestEditPage />} />
          <Route path="tests/listening" element={<ListeningTestsPage />} />
          <Route
            path="tests/listening/edit/:id"
            element={<ListeningTestEditPage />}
          />
          <Route
            path="tests/listening/new"
            element={<ListeningTestEditPage />}
          />

          {/* IELTS Courses */}
          <Route path="courses/ielts" element={<AdminIeltsCoursesPage />} />
          <Route path="courses/ielts/new" element={<CourseEditPage />} />
          <Route path="courses/ielts/edit/:id" element={<CourseEditPage />} />
          <Route
            path="courses/ielts/:courseId/lessons"
            element={<LessonsPage />}
          />
          <Route
            path="courses/ielts/:courseId/lessons/new"
            element={<LessonEditPage />}
          />
          <Route
            path="courses/ielts/:courseId/lessons/edit/:id"
            element={<LessonEditPage />}
          />
          <Route
            path="courses/ielts/:courseId/lessons/edit/:lessonId/reading/new"
            element={<ReadingTestEditPage />}
          />
          <Route
            path="courses/ielts/:courseId/lessons/edit/:lessonId/reading/edit/:id"
            element={<ReadingTestEditPage />}
          />
          {/* End IELTS Courses */}

          {/* Junior Courses */}
          <Route path="courses/junior" element={<AdminJuniorCoursesPage />} />
          <Route path="courses/junior/new" element={<CourseEditPage />} />
          <Route path="courses/junior/edit/:id" element={<CourseEditPage />} />
          <Route
            path="courses/junior/:courseId/lessons"
            element={<LessonsPage />}
          />
          <Route
            path="courses/junior/:courseId/lessons/new"
            element={<LessonEditPage />}
          />
          <Route
            path="courses/junior/:courseId/lessons/edit/:id"
            element={<LessonEditPage />}
          />
          {/* End Junior Courses */}

          {/* Other */}
          <Route path="my-profile" element={<ProfilePage />} />
          <Route path="results" element={<AdminResultsPage />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
