import { Form, Formik } from "formik";
import _, { isEmpty } from "lodash";
import { Fragment, useRef, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import * as Yup from "yup";
import Input from "../Form/Input";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import RichEditor from "../RichEditor/RichEditor";
import AdminListeningTestForm from "../AdminTestParts/AdminListeningTestForm";

const ValidateEditSchema = Yup.object().shape({
  name: Yup.string().required("Vui lòng nhập tên"),
  description: Yup.string(),
  // numTracks: Yup.number()
  //   .required("Vui lòng nhập số track")
  //   .min(1, "Số track tối thiểu là 1")
  //   .max(10, "Số track tối đa là 10"),
  //listeningAudio: Yup.string().required("Vui lòng upload file nghe"),
  listeningFile: Yup.string(),
});

export default function AdminListeningLessonForm({
  initValue,
  isLoading,
  onSubmit,
  onCancel,
}) {
  const examRef = useRef(null);
  const [audioSources, setAudioSources] = useState(initValue.listeningAudios);

  const addFile = (event, index, setFieldValue) => {
    if (event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue(`listeningAudios[${index}]`, reader.result);
        setAudioSources((audioSources) =>
          audioSources.map((x, i) => (i === index ? reader.result : x))
        );
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChangeNumTracks = (
    newNumTracks,
    listeningAudios,
    setFieldValue
  ) => {
    const diff = newNumTracks - listeningAudios.length;
    if (newNumTracks > 20 || newNumTracks < 1 || diff === 0) {
      return;
    }

    if (diff < 0) {
      if (
        !window.confirm(
          "Thay đổi số track có thể dẫn đến mất dữ liệu bạn đã nhập. Bạn có chắc chắn muốn thay đổi ko?"
        )
      ) {
        return;
      }
    }

    if (diff > 0) {
      listeningAudios.push(
        ...Array.from({ length: newNumTracks - listeningAudios.length })
      );
    } else {
      const deleteCount = listeningAudios.length - newNumTracks;
      listeningAudios.splice(newNumTracks, deleteCount);
    }

    setFieldValue("numTracks", newNumTracks);
    setFieldValue("listeningAudios", listeningAudios);
    setAudioSources(listeningAudios);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValue}
      validateOnMount={true}
      validationSchema={ValidateEditSchema}
      onSubmit={_.debounce((values) => {
        if (!isLoading) {
          if (
            isEmpty(values.lessonExaminations) &&
            examRef.current?.values?.id
          ) {
            values.lessonExaminations.push({
              lessonId: values.id,
              examinationId: examRef.current.values.id,
            });
          }
          onSubmit(values);
          examRef.current?.handleSubmit();
        }
      }, 500)}
    >
      {({ setFieldValue, values, errors, touched, isValid }) => (
        <Form className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <Input
                label="Tên"
                name="name"
                placeholder="Tên bài học"
                required
                error={errors.name}
                touched={touched.name}
              />
            </div>
          </div>
          <div className="form-group">
            <Input
              label="Mô tả"
              name="description"
              as="textarea"
              placeholder="Mô tả"
              error={errors.description}
              touched={touched.description}
            />
          </div>
          <div className="form-group row">
            <div className="col-lg-4">
              <Input
                label="Số track"
                name="numTracks"
                type="number"
                placeholder="Số track"
                required
                error={errors.name}
                touched={touched.name}
                onChange={(e) =>
                  handleChangeNumTracks(
                    e.target.value,
                    values.listeningAudios,
                    setFieldValue
                  )
                }
              />
            </div>
          </div>
          {values.numTracks > 0 &&
            Array.from({ length: values.numTracks }).map((_, index) => (
              <Fragment key={index}>
                <div className="form-group">
                  <Input
                    label={`Track ${index + 1}`}
                    name="listeningFile"
                    placeholder={`Track ${index + 1}`}
                    type="file"
                    accept=".mp3,.wav"
                    onChange={(e) => addFile(e, index, setFieldValue)}
                    error={errors.listeningAudio}
                    touched={touched.listeningFile}
                  />
                </div>
                {audioSources[index] && (
                  <div className="form-group">
                    <audio
                      className="listening-audio"
                      controls
                      playsinline
                      preload="metadata"
                      webkit-playsinline
                    >
                      <source
                        src={audioSources[index]}
                        type="audio/mpeg"
                      />
                      <p>Your browser does not support audio element</p>
                    </audio>
                  </div>
                )}
              </Fragment>
            ))}

          <Tabs>
            <Tab eventKey="script" title="Script">
              <div className="form-group">
                <RichEditor
                  fieldName="content"
                  setFieldValue={setFieldValue}
                  value={values.content}
                  error={errors.content}
                  touched={touched.content}
                />
              </div>
            </Tab>
            <Tab eventKey="vocab" title="Vocab">
              <div className="form-group">
                <RichEditor
                  fieldName="vocabulary"
                  setFieldValue={setFieldValue}
                  value={values.vocabulary}
                  error={errors.vocabulary}
                  touched={touched.vocabulary}
                />
              </div>
            </Tab>
            <Tab eventKey="quiz" title="Quiz">
              <div className="form-group mt-4">
                <AdminListeningTestForm
                  formRef={examRef}
                  hideAudioInput={true}
                  id={values.lessonExaminations[0]?.examinationId}
                  lessonId={values.id}
                />
              </div>
            </Tab>
          </Tabs>

          <div className="form-action">
            <Button
              type="button"
              onClick={onCancel}
              className="btn btn-light btn-elevate"
            >
              Hủy
            </Button>
            <Button
              disabled={isLoading || !isValid}
              className="btn btn-primary btn-elevate"
              type="submit"
            >
              {isLoading && <LoadingIndicator />}
              {!isLoading && "Lưu"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
