import { React } from "react";
import JuniorPartsResult from "../../components/PraceticeTestParts/JuniorPartsResult";
import TestResultContainer from "../../components/StudentTestParts/TestResultContainer";
import { ExaminationType } from "../../../utils/constants";
import ReadingPartsResult from "../../components/IeltsParts/ReadingPartsResult";
import ListeningPartsResult from "../../components/IeltsParts/ListeningPartsResult";

export default function StudentResultPage() {
  const getDetailComponent = (result) => {
    const {
      examination: { examinationType },
    } = result;

    switch (examinationType) {
      case ExaminationType.Reading:
        return ReadingPartsResult;
      case ExaminationType.Listening:
        return ListeningPartsResult;
      case ExaminationType.Junior:
        return JuniorPartsResult;
      default:
        return "";
      // TODO: Add more examination type here when we have more
    }
  };

  return (
    <TestResultContainer
      backUrl="/results"
      renderResultDetail={(result, returnBack) => {
        const DetailComponent = getDetailComponent(result);
        return DetailComponent ? (
          <DetailComponent result={result} onReturnBack={returnBack} />
        ) : null;
      }}
    />
  );
}
