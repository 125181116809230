import React from "react";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../utils/Table/TableSortingUtils";
import MasterLayout from "../../components/MasterDetail/MasterLayout/MasterLayout";
import ResultColumn from "../../components/MasterDetail/ColumnFormatter/ResultColumn";
import { useGetExaminationResultByUserQuery } from "../../../apis/ExaminationResultApi";
import DateColumn from "../../components/MasterDetail/ColumnFormatter/DateColumn";
import Input from "../../components/Form/Input";
import { useNavigate } from "react-router-dom";

export default function StudentResultsPage() {
  const navigate = useNavigate();

  // Table columns
  const columns = [
    {
      dataField: "examinationName",
      text: "Tên bài test",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "numRightQuestions",
      text: "Số câu hỏi đúng",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: ResultColumn,
    },
    {
      dataField: "createdDate",
      text: "Thời điểm thi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: DateColumn,
    },
    {
      dataField: "ChiTiet",
      text: "Chi tiết",
      formatter: (cell, row) => {
        return (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate(`/results/${row.id}`)}
          >
            Chi tiết
          </button>
        );
      },
    },
  ];

  const renderAdditionalFilterComponent = (handleChange) => (
    <div className="row g-3">
      <div className="col-lg-6">
        <Input
          type="date"
          name="fromDate"
          label="Từ ngày"
          format="dd/MM/yyyy"
          onChange={handleChange}
        />
      </div>
      <div className="col-lg-6">
        <Input
          type="date"
          name="toDate"
          label="Đến ngày"
          onChange={handleChange}
        />
      </div>
    </div>
  );

  return (
    <MasterLayout
      title="Kết quả"
      columns={columns}
      useFindItemsMutation={useGetExaminationResultByUserQuery}
      disabledAll={true}
      enabledExport={true}
      renderAdditionalFilterComponent={renderAdditionalFilterComponent}
    />
  );
}
