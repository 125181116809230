import { useMutation, useQuery } from "react-query";
import baseApi from "./BaseApi";

const EXAMINATION_RESULT_URL = "/examinationResults";
const FIND_EXAMINATION_RESULT_URL = `${EXAMINATION_RESULT_URL}/find`;
const FIND_EXAMINATION_RESULT_USER_URL = `${EXAMINATION_RESULT_URL}/find-by-user`;

export function useFindExaminationResultsMutation() {
  return useMutation(FIND_EXAMINATION_RESULT_URL, async (queryParams) => {
    return (await baseApi.post(FIND_EXAMINATION_RESULT_URL, queryParams)).data;
  });
}

export function useCreateExaminationResultMutation() {
  return useMutation(EXAMINATION_RESULT_URL, async (result) => {
    return (await baseApi.post(EXAMINATION_RESULT_URL, result)).data;
  });
}

export function useGetExaminationResultByIdQuery(id) {
  return useQuery(`${EXAMINATION_RESULT_URL}/${id}`, async () => {
    if (id) {
      return (await baseApi.get(`${EXAMINATION_RESULT_URL}/${id}`)).data;
    } else {
      return null;
    }
  });
}

export function useGetSummaryExaminationResultByUserQuery() {
  return useQuery(`${EXAMINATION_RESULT_URL}/user-summary`, async () => {
    return (await baseApi.get(`${EXAMINATION_RESULT_URL}/user-summary`)).data;
  });
}

export function useGetExaminationResultByUserQuery() {
  return useMutation(FIND_EXAMINATION_RESULT_USER_URL, async (queryParams) => {
    return (await baseApi.post(FIND_EXAMINATION_RESULT_USER_URL, queryParams)).data;
  });
}