import { React } from "react";
import ReadingPartsResult from "../../../components/IeltsParts/ReadingPartsResult";
import TestResultContainer from "../../../components/StudentTestParts/TestResultContainer";

export default function ReadingResultPage() {
  return (
    <TestResultContainer
      backUrl="/ielts/reading"
      renderResultDetail={(result, returnBack) => (
        <ReadingPartsResult result={result} onReturnBack={returnBack} />
      )}
    />
  );
}
