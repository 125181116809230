import React from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import { useState } from "react";
import {
  IeltsSidebarData,
  AdminSidebarData,
  JuniorSidebarData,
  Junior6SidebarData,
  Junior7SidebarData,
  Junior8SidebarData,
} from "./SidebarData";
import SubMenuSidebar from "./SubMenuSidebar";
import { Button } from "react-bootstrap";
import { useAuthStore } from "../../../../stores/AuthStore";
import { UserRoles } from "../../../../utils/constants";
import { useRef } from "react";
import { useOutsideClick } from "../../../../hooks/OutsideClickHook";
import Logo from "../../../../assets/img/logo.png";
import { useEffect } from "react";

const Nav = styled.div``;
const SidebarNav = styled.nav`
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
`;
const SidebarWrap = styled.div``;

export default function Sidebar() {
  const [showSidebar, setShowSidebar] = useState(false);
  const changeShowSidebar = () => setShowSidebar(!showSidebar);
  const closeSideBar = () => {
    if (showSidebar) {
      setShowSidebar(false);
    }
  };
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, closeSideBar);

  const { currentUser } = useAuthStore();

  let sidebarData = [];
  switch (currentUser.role) {
    case UserRoles.Admin:
      sidebarData = AdminSidebarData;
      break;
    case UserRoles.Ielts:
      sidebarData = IeltsSidebarData;
      break;
    case UserRoles.Junior:
      sidebarData = JuniorSidebarData;
      break;
    case UserRoles.Junior6:
      sidebarData = Junior6SidebarData;
      break;
    case UserRoles.Junior7:
      sidebarData = Junior7SidebarData;
      break;
    case UserRoles.Junior8:
      sidebarData = Junior8SidebarData;
      break;
    default:
      break;
  }

  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => closeSideBar(), [location.pathname]);

  return (
    <section className="sidebar">
      <Nav>
        <Button
          className="toggle-open"
          variant="light"
          onClick={changeShowSidebar}
        >
          <FaIcons.FaBars />
        </Button>
      </Nav>
      <SidebarNav
        className="sidebar-nav"
        sidebar={showSidebar}
        ref={wrapperRef}
      >
        <SidebarWrap className="sidebar-wrap">
          <div className="nav-header py-3">
            <Button
              className="toggle-open"
              variant="light"
              onClick={changeShowSidebar}
            >
              <FaIcons.FaBars />
            </Button>
            <img src={Logo} alt="logo" />
          </div>

          <div className="sidebar-menu">
            {sidebarData.map((item, index) => {
              return <SubMenuSidebar item={item} key={index}></SubMenuSidebar>;
            })}
          </div>
        </SidebarWrap>
      </SidebarNav>
    </section>
  );
}
